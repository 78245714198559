<template>
  <div class="add-product-container">
    <div class="container-title">
      <a-breadcrumb separator=">" class="breadcrumb">
        <a-breadcrumb-item>
          <a @click.prevent="$router.push('/index')">首页</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a
            @click.prevent="
              $router.push(
                `/buyercenter/stock?entityId=${$route.query.entityId}`
              )
            "
            >库存管理</a
          >
        </a-breadcrumb-item>
        <a-breadcrumb-item> 库存创建订单 </a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="container">
      <a-card class="add-content">
        <a-alert class="cascader-alert">
          <p slot="description">
            <b>您当前选择的商品类别是：</b>
            {{ info.categoryFirstValue + '>' + info.categorySecondValue }}
          </p>
        </a-alert>

        <a-card class="l-card">
          <div class="title">分类属性</div>
          <a-row>
            <a-col
              :span="24"
              v-for="(item, index) in selectClassify.property"
              :key="item.id"
            >
              <a-col :span="3" class="label-right">
                <span class="font-error" v-if="index === 0">*</span>
                {{ item.attrName }}：
              </a-col>
              <a-col :span="11">
                <a-select
                  show-search
                  :value="item.value"
                  @change="item.value = $event"
                  style="width: 240px"
                  :placeholder="'请选择' + item.attrName"
                >
                  <a-select-option v-for="name in item.attrValue" :key="name">
                    {{ name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col v-if="index === 0" :span="10" style="line-height: 32px">
                <a-switch
                  :checked="form.isSpec"
                  @click="form.isSpec = !form.isSpec"
                  style="margin-right: 16px"
                >
                  <a-icon slot="checkedChildren" type="check" />
                  <a-icon slot="unCheckedChildren" type="close" />
                </a-switch>
                <span>发票上是否显示该规格</span>
              </a-col>
            </a-col>
          </a-row>
        </a-card>

        <a-card class="l-card">
          <div class="title">发票品类</div>
          <a-row>
            <!-- <a-col :span="3" class="label-right">
              <span class="font-error">*</span>发票类型：
            </a-col> -->
            <!-- <a-col :span="8">
              <a-select
                :value="taxRate.id"
                @change="typeTaxChange"
                placeholder="请选择发票类型"
                style="width: 100%"
                :dropdownMatchSelectWidth="false"
              >
                <a-select-option v-for="item in taxRateList" :key="item.id">
                  {{ item.policyName }}
                </a-select-option>
              </a-select>
            </a-col> -->
            <a-col :span="3" class="label-right">
              <span class="font-error">*</span>品类名称：
            </a-col>
            <a-col :span="8">
              <a-select
                style="width: 100%"
                :value="invoiceInfo.index"
                @change="invoiceInfo.index = $event"
                placeholder="请选择品类名称"
              >
                <a-select-option
                  v-for="(item, index) in invoiceInfo.list"
                  :key="index"
                >
                  {{ item.taxClassName }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="3" class="label-right">品类编码：</a-col>
            <a-col :span="8" style="line-height: 32px">{{
              (invoiceInfo.list.length &&
                invoiceInfo.index !== undefined &&
                invoiceInfo.list[invoiceInfo.index].taxClassCode) ||
              ''
            }}</a-col>
            <a-col :span="3" class="label-right">规格型号：</a-col>
            <a-col :span="8" style="line-height: 32px">{{
              (form.isSpec &&
                selectClassify.property.length &&
                selectClassify.property[0].value) ||
              ''
            }}</a-col>
          </a-row>
        </a-card>

        <a-row type="flex" align="middle">
          <a-col :span="24">
            <a-space :size="24">
              <span class="title-left"> 下游管理 </span>
              <a-input
                style="width: 320px"
                :value="info.downstreamInfo&&info.downstreamInfo.downstreamName"
                disabled
              ></a-input>
              <span class="title-right"> * 库存关联下游不可修改 </span>
            </a-space>
          </a-col>

          <a-col :span="24" class="title-left">发货地址</a-col>
          <a-col :span="24" class="container-border">
            <a-col :span="16" >
              <div class="col">
                <span class="label-text">发货地址：</span>{{ info.deliverPlaceFullAddress }}
              </div>
              
            </a-col>
            <a-col :span="8" align="middle" style="color: rgba(127, 127, 127, 1);">
              *多笔库存创建订单时，默认取时间最早库存的发货地信息
            </a-col>
          </a-col>

          <a-col :span="24" class="title-left">收货地址</a-col>
          <a-col :span="24" class="container-border">
            <a-col :span="16" >
              <div class="col">
                <span class="label-text">收货地址：</span>{{ info.downstreamInfo&&info.downstreamInfo.warehouseAddress }}
              </div>
              
            </a-col>
            <a-col :span="8" align="middle" style="color: rgba(127, 127, 127, 1);">
              *下游企业详细地址，不可修改
            </a-col>
          </a-col>
        </a-row>

        <a-row type="flex" align="middle" class="lh60 mt42">
          <a-col :span="24"> 确认订单信息 </a-col>
        </a-row>

        <a-spin :spinning="spinTop">
          <div class="p-table-title">
            <div class="p-name">供应商商品</div>
            <div class="bill-name">发票名称</div>
            <div class="p-type">规格</div>
            <div class="p-price">单价(不含税)</div>
            <div class="p-number">数量</div>
            <div class="p-tax">金额(不含税)</div>
            <div class="p-tax">增值税</div>
            <div class="p-sub">小计(含税费)</div>
          </div>

          <a-table :columns="columns" :rowKey="(row, index) => index" :pagination="false" :dataSource="data_" class="p_table">
            <template slot="p_name">
              <a-space :size="12">
                <a-avatar shape="square" class="square" :size="50" :src="info.previewItemVo&&info.previewItemVo.goodPic&&info.previewItemVo.goodPic.imageUrl || noImg" />
                <div class="goods-name">{{tableName}}</div>
              </a-space>
              <!-- <span class="">{{text}}</span> -->
            </template>
            <template slot="name" >
              <span>{{tableRaxName.taxName}}</span>
            </template>
            <template slot="specName" >
              <span>{{tableRaxName.sizeName}}</span>
            </template>
            <template slot="p_sub" slot-scope="text">
              <span class="p_sub_text">{{text}}</span>
            </template>
            <template slot="taxableUnitPrice" slot-scope="text">
              <a-space v-if="!editPrice">{{text}} <a-icon type="form"  style="margin-left:10px" @click="editPrice=true;editPriceNumber=data_[0].taxableUnitPrice"/></a-space>
              <a-space v-else>
                <a-input-number
                  :default-value="0"
                  :formatter="value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                  :parser="value => value.replace(/\¥\s?|(,*)/g, '')"
                  @change="onChangePriceNumber"
                  v-model = 'editPriceNumber'
                  style="width:100%"
                />
                <a-icon type="check" @click="onConfirmPrice"/>
                <a-icon type="close" @click="editPrice=false" />
              </a-space>
            </template>
            <template slot="vatAmount">
              <a-select
                  show-search
                  :value="policyId"
                  @change="onChangePolicy"
                  :placeholder="'请选择'"
                  v-if="info.previewItemVo"
                >
                  <a-select-option v-for="item in info.previewItemVo.taxRegionPolicies" :key="item.id">
                    {{ item.policyName }}
                  </a-select-option>
                </a-select>
            </template>
            <template slot="footer">
              <div class="table-footer">
                <div class="remark">
                  <div class="remark-label">备注：</div>
                  <a-textarea v-model="form.orderRemark" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }" />
                </div>
                <div class="f-total">
                  <div class="total-text">店铺合计（含税费）</div>
                </div>
                <div class="total-pic">¥{{data_[0]&&data_[0].totalAmount}}</div>
              </div>
            </template>
          </a-table>
        </a-spin>

        <a-row type="flex" align="middle">
          <a-col :span="24" class="lh60 mt42"> 发票信息确认（预览页，以实际拆分开票后为准） </a-col>
          <a-col :span="24" align="center"><span class="invoice-title">增值税电子普通发票</span></a-col>
          <a-col :span="24">
          <a-spin :spinning="spinTop">
            <a-table class="invoiceTable" :rowKey="(row, index) => index" :dataSource="dataInvoice" bordered size="small"
              :columns="columnsInvoice" :pagination="false">
              <span style="writing-mode: vertical-lr;" slot="purchaserTitle">购&#12288;买&#12288;方</span>
              <a-row slot="invoiceTitle" class="table-row">
                <a-col :span="12">
                  <span>名&#12288;&#12288;&#12288;&#12288;称：</span>{{purchaserInvoiceInfo.commercialName}}
                </a-col>
                <a-col :span="12" class="table-col">
                  <span>电子发票邮箱：</span>{{purchaserInvoiceInfo.invoiceEmail}}
                  <a-button type="primary" class="w140" @click="amendinfo = true">
                    <a-icon type="edit" /> 修改发票抬头
                  </a-button>
                </a-col>
                <a-col :span="24">
                  <span>纳税人识别号：</span>{{purchaserInvoiceInfo.uniformCode}}
                </a-col>
                <a-col :span="24">
                  <span>地址、&#12288;电话：</span>{{purchaserInvoiceInfo.invoiceAddr}}&nbsp;
                  {{purchaserInvoiceInfo.invoiceMobile}}
                </a-col>
                <a-col :span="24">
                  <span>开户行及账号：</span>{{purchaserInvoiceInfo.invoiceBankName}}&nbsp;
                  {{purchaserInvoiceInfo.invoiceBankAcc}}
                </a-col>
              </a-row>
            </a-table>
          </a-spin>
          </a-col>
        </a-row>

        
      </a-card>
      <a-row type="flex" justify="end" align="middle" class="create-order">
          <a-col :span="24" style="padding-top: 40px" align="center">
            <a-space :size="32">
              <a-button class="btn-w" size="large" @click="$router.back()">返回</a-button>
              <a-button type="primary" size="large" class="btn-w" :loading="loading" @click="create">生成订单</a-button>
            </a-space>
          </a-col>
        </a-row>
      <!-- <div class="create">
        <a-button type="danger" size="large" :loading="loading" @click="create">生成订单</a-button>
      </div> -->
    </div>
    <stockBillInfo
      ref="stockBillInfo"
      :obj="billInfo"
      @change="getsupplierinf"
    />
    <site
      v-if="showSite"
      :siteType="siteType"
      :show.sync="showSite"
      :defaultId="siteObj.id"
      @change="siteChange"
    />


    <!-- 发票信息 -->
    <a-modal :centered="true" :width="600" :visible="amendinfo" cancelText="取消" okText="保存" :maskClosable="false"
      @ok="invoiceOk" @cancel="amend">
      <a-row>
        <a-col align="center" class="fs16">发票信息</a-col>
      </a-row>
      <a-row>
        <a-col>发票抬头</a-col>
        <a-col class="pt5">
          <a-input disabled v-model="purchaserInvoiceInfo.commercialName"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>纳税人识别号</a-col>
        <a-col class="pt5">
          <a-input disabled v-model="purchaserInvoiceInfo.uniformCode"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票地址</a-col>
        <a-col class="pt5">
          <a-input @input="purchaserInvoiceInfo.invoiceAddr=$event.target.value.replace(/^\s+|\s+$/g,'')"
            v-model="purchaserInvoiceInfo.invoiceAddr"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票电话</a-col>
        <a-col class="pt5">
          <a-input :maxLength="18" v-model="purchaserInvoiceInfo.invoiceMobile"
            @input="purchaserInvoiceInfo.invoiceMobile=$event.target.value.replace(/[^\d|-]/g,'')"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票银行名称</a-col>
        <a-col class="pt5">
          <a-input @input="purchaserInvoiceInfo.invoiceBankName=$event.target.value.replace(/^\s+|\s+$/g,'')"
            v-model="purchaserInvoiceInfo.invoiceBankName"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票银行账号</a-col>
        <a-col class="pt5">
          <a-input :maxLength="30"
            @input="purchaserInvoiceInfo.invoiceBankAcc=$event.target.value.replace(/^\s+|\s+$/g,'')"
            v-model="purchaserInvoiceInfo.invoiceBankAcc"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>电子发票邮箱</a-col>
        <a-col class="pt5">
          <a-input v-model="purchaserInvoiceInfo.invoiceEmail"></a-input>
        </a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票须知：</a-col>
        <a-col>1. 订单付款后纳税人识别号不可修改，请确认信息后付款；</a-col>
        <a-col>2.
          在订单完成后申请开票，纸制发票我方会统一开票后寄给买方，电子发票会通过邮件发送。</a-col>
        <a-col>3. 发票金额为实际支付金额。</a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { dsPur } from '@/api/buyerCenter'
import { getdefault } from '@/api/order'
import { getPreview, create } from '@/api/dsm'
import {
  querySecondAttr,
  queryCateroryName,
  queryRegionPolicy,
  updateinvoice,
  getsupplierinf, 
  usualaddress,
  evaluation
} from '@/api/seller'
import stockBillInfo from './components/stockBillInfo.vue'
import site from './components/SiteCopy'
import options from '@/utils/ara'
import { type } from '@/utils/constArr'
import { convertCurrency } from '@/utils/index'

export default {
  components: {
    stockBillInfo,
    site
  },
  data() {
    const columnsInvoice = [
      {
        dataIndex: 'purchaser',
        key: 'purchaser',
        align: 'center',
        slots: { title: 'purchaserTitle' },
        children: [
          {
            dataIndex: 'age',
            align: 'center',
            key: 'age',
            customRender: (value, row, index) => {
              const obj = {
                children: row.config !== 'space' ? index + 1 : (<div style="visibility: hidden;">{index}</div>),
                attrs: {}
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                const filter = {
                  total: '合计',
                  upperCase: '价税合计（大写）'
                }
                obj.attrs.colSpan = 2
                obj.children = filter[row.config]
              }
              return obj
            }
          },
        ],
      },
      {
        slots: { title: 'invoiceTitle' },
        children: [
          {
            title: '货物或应税劳务名称',
            dataIndex: 'invoiceName',
            align: 'center',

            customRender: (value, row, index) => {
              const obj = {
                children: index==0?this.tableRaxName.taxName:'',
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'invoiceName'
          },
          {
            title: '规格型号',
            dataIndex: 'specName',
            align: 'center',
            customRender: (value, row, index) => {
              const obj = {
                children: index==0&&this.form.isSpec?this.tableRaxName.sizeName:'',
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.class = 'font-foot'
                obj.attrs.colSpan = 7
                obj.children = row.value
              }
              return obj
            },
            key: 'specName'
          },
          {
            title: '单位',
            dataIndex: 'unitsMeasure',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: type[value],
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'unitsMeasure'
          },
          {
            title: '数量',
            dataIndex: 'quantity',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value,
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'quantity'
          },
          {
            title: '单价(不含税)',
            dataIndex: 'taxableUnitPrice',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value,
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'unitPrice'
          },
          {
            title: '金额(不含税)',
            dataIndex: 'taxableAmount',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value,
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'taxableAmount'
          },
          {
            title: '税率',
            dataIndex: 'vatRate',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value !== undefined && Math.round(value * 1000000) / 10000 + '%',
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'vatRate'
          },
          {
            title: '税额',
            dataIndex: 'vatTaxAmount',
            align: 'center',
            customRender: (value, row) => {
              const obj = {
                children: value !== undefined && value.toFixed(2),
                attrs: { class: 'table-list' }
              }
              if (row.config === 'total' || row.config === 'upperCase') {
                obj.attrs.colSpan = 0
              }
              return obj
            },
            key: 'vatTax'
          }
        ],
      }
    ]
    return {
      noImg: require('@/assets/images/noImg.png'),
      type,
      columns:[],
      options,
      loading: false,
      info: {},
      data: [],
      dsList: [], // 下游管理列表
      downstreamId: null,
      taxRateList: [], // 发票类型
      // 税率
      taxRate: {
        id: '',
        policyName: '',
        vatRate: 0
      },
      // 一级二级三级分类控制
      selectClassify: {
        levelPrimary: '',
        levelBy: '',
        property: []
      },
      // 发票信息
      invoiceInfo: {
        list: [],
        index: undefined
      },
      siteObj: {
        id: undefined
      }, // 地址
      // 发票
      billInfo: {
        id: this.$route.query.entityId,
        supplierName: '',
        unifiedCode: '',
        invoiceBankName: '',
        invoiceBankAcc: '',
        invoiceMobile: '',
        invoiceEmail: '',
        invoiceAddr: ''
      },
      totalPrice: 0, // 合计付款
      showSite: false, // 地址
      siteType: 1, // 地址类型
      usualaddress: [], // 常用地址
      cascaderValue: [],
      form: {
        inIds: this.$route.query.inIds,
        // addrDetail: '', // 地址信息
        unitsMeasure: 'T',
        isSpec: true, // 发票上是否显示该规格
        orderRemark: '', // 备注
      },
      
      spinTop:false,
      data_: [],
      dataInvoice: [],
      purchaserInvoiceInfo: {
        id: this.$route.query.purchaserId, //买家id
        commercialName: '', //主体名称，发票抬头
        uniformCode: '', //证件号：企业统一信用代码
        invoiceAddr: '', //发票地址
        invoiceMobile: '', //发票电话
        invoiceBankName: '', //发票银行开户行
        invoiceBankAcc: '', //发票银行账号
        invoiceEmail: '' //电子发票投递邮箱
      },
      columnsInvoice,
      editPriceNumber: 0,
      editPrice:false,
      policyId:0,
      amendinfo:false
    }
  },
  mounted() {
    dsPur(this.$route.query.entityId).then((res) => {
      if (res.code === 200) {
        this.dsList = res.data
      }
    })
    getPreview({ inIds: this.$route.query.inIds }).then((res) => {
      console.log(res)
      if (res.code !== 200) {
        this.$message.error(res.message)
        return
      }
      this.info = res.data
      this.getCheckOption(this.info.categorySecondId)
      queryRegionPolicy(this.info.previewItemVo.supplierId).then((res) => {
        if (res.code !== 200) {
          this.$message.error(res.message)
        } else {
          if (!res.data || !res.data.length) {
            this.$message.error('卖家未绑定税费计算政策')
            this.taxDisabled = true
            return
          }
          this.taxRateList = res.data
          const { id, policyName, vatRate } = res.data[0]
          this.taxRate = { id, policyName, vatRate }
        }
      })
      usualaddress(this.info.supplierId).then(res => {
          if (res.code === 200) {
              this.usualaddress = res.data
          }
      })
      this.columns = [{
            title: '供应商：' +  res.data.previewItemVo.supplierName,
            dataIndex: 'mdseName',
            width: '20%',
            align: 'left',
            scopedSlots: { customRender: 'p_name' }
          },
          {
            title: '卖家：' +  res.data.previewItemVo.legalName + ' ' +  res.data.previewItemVo.legalPhone,
            dataIndex: 'invoiceName',
            width: '17%',
            scopedSlots: { customRender: 'name' }
          },
          {
            title: '',
            dataIndex: 'specName',
            width: '13%',
            align: 'center',
            scopedSlots: { customRender: 'specName' }
          },
          {
            title: '',
            dataIndex: 'taxableUnitPrice',
            width: '15%',
            align: 'center',
            scopedSlots: { customRender: 'taxableUnitPrice' }
          },
          {
            title: '单位：' + type[res.data.previewItemVo.unitsMeasure],
            dataIndex: 'quantity',
            width: '8%',
            align: 'center',
            scopedSlots: { customRender: 'quantity' }
          },
          {
            title: '',
            dataIndex: 'taxableAmount',
            width: '10%',
            align: 'center'
          },
          {
            title: '' ,
            dataIndex: 'vatAmount',
            width: '10%',
            align: 'center',
            scopedSlots: { customRender: 'vatAmount' }
          },
          {
            title: '',
            dataIndex: 'totalAmount',
            align: 'center',
            scopedSlots: { customRender: 'p_sub' }
          }]

      this.data_ = [
        {
          custom: true,
          unitsMeasure: res.data.previewItemVo.unitsMeasure,
          invoiceName: this.tableRaxName.taxName,
          specName: '单位：吨',
          taxableUnitPrice: res.data.previewItemVo.taxableUnitPrice,
          quantity:res.data.previewItemVo.quantity,
          taxableAmount:res.data.previewItemVo.taxableAmount,
          vatAmount:res.data.previewItemVo.vatAmount,
          totalAmount:res.data.previewItemVo.contractAmount,
          vatTaxAmount:res.data.previewItemVo.taxAmount,
          vatRate:res.data.previewItemVo.taxRegionPolicies.find(item=>item.id==res.data.previewItemVo.taxPolicyId).vatRate
        }
      ]
      this.policyId = res.data.previewItemVo.taxPolicyId
      this.purchaserInvoiceInfo = res.data.purchaserInvoiceInfo
      this.purchaserInvoiceInfo_ = JSON.parse(JSON.stringify(this.purchaserInvoiceInfo)) 
      this.dataInvoice = [...this.data_,{
        config: "space",
      },{
        config: "total",
        value: this.data_[0].totalAmount
      },{
        config: "upperCase",
        value: convertCurrency(this.data_[0].totalAmount)
      },]
    })
    this.getsupplierinf()
    this.getdefaults()
  },
  computed: {
    tableName() {
      let str = ''
      this.selectClassify.property.forEach(
        (item) => (str += ` ${item.value || ''}`)
      )
      return str
    },
    tableRaxName() {
      // return this.invoiceInfo.list.length && this.selectClassify.property.length && `发票品名：${this.invoiceInfo.list[this.invoiceInfo.index].taxClassName}
      // 规格：${this.selectClassify.property[0].value}`
      if (this.invoiceInfo.list.length && this.selectClassify.property.length) {
        return {
          taxName: `${
            this.invoiceInfo.list[this.invoiceInfo.index].taxClassName
          }`,
          sizeName: `${this.selectClassify.property[0].value}`
        }
      }
      return ''
    }
  },
  methods: {
    //更新发票表格
    updateTableTax(){
      this.dataInvoice = [...this.data_,{
        config: "space",
      },{
        config: "total",
        value: this.data_[0].totalAmount
      },{
        config: "upperCase",
        value: convertCurrency(this.data_[0].totalAmount)
      },]
    },
    onConfirmPrice(){
      if(this.editPriceNumber<=0){
        this.$message.error('单价不能小于等于0')
        // this.editPriceNumber = this.data_[0].taxableUnitPrice
        return
      }
      evaluation(this.info.previewItemVo.supplierId, this.editPriceNumber, this.policyId,'taxableUnitPrice',this.info.previewItemVo.quantity).then(res=>{
        if (res.code === 200) {
          const result = res.data[0] || {}
          this.data_[0].taxableAmount = result.taxableAmount
          this.data_[0].totalAmount = result.contractAmount
        }else {
          this.$message.error(res.message)
        }
        
        this.updateTableTax()
      })
      this.editPrice=false;
      this.data_[0].taxableUnitPrice=this.editPriceNumber
    },
    async onChangePolicy(policyId) {
      this.policyId = policyId
      console.log(policyId)
      const res = await evaluation(this.info.previewItemVo.supplierId, this.info.previewItemVo.netAmount, policyId,'netAmount',this.info.previewItemVo.quantity)
      const result = res.data[0] || {}
      this.data_[0].taxableAmount = result.taxableAmount
      this.data_[0].totalAmount = result.contractAmount
      this.data_[0].taxableUnitPrice = result.taxableUnitPrice
      this.data_[0].vatRate = this.info.previewItemVo.taxRegionPolicies.find(item=>item.id== policyId).vatRate
      this.updateTableTax()
    },
    amend() {
      this.amendinfo = false
      this.purchaserInvoiceInfo = JSON.parse(JSON.stringify(this.purchaserInvoiceInfo_))
      // this.getconfirm()
    },
    verify() {
      const regEmail = /.{1,}@.{1,}\..{1,}/
      if (!this.purchaserInvoiceInfo.invoiceAddr) {
        this.$message.error('请填写发票地址')
      } else if (!this.purchaserInvoiceInfo.invoiceMobile) {
        this.$message.error('请填写正确的发票电话')
      } else if (!this.purchaserInvoiceInfo.invoiceBankName) {
        this.$message.error('请填写发票银行名称')
      } else if (!this.purchaserInvoiceInfo.invoiceBankAcc) {
        this.$message.error('请填写发票银行账户')
      } else if (!regEmail.test(this.purchaserInvoiceInfo.invoiceEmail)) {
        this.$message.error('请填写正确的电子发票邮箱')
      } else return true
    },
    // 修改发票信息
    invoiceOk() {
      if (!this.verify(true)) return
      updateinvoice({
        entityCharacter: 'P',
        ...this.purchaserInvoiceInfo,
      }).then((res) => {
        if (res.code === 200) {
          this.purchaserInvoiceInfo_ = JSON.parse(JSON.stringify(this.purchaserInvoiceInfo)) 
          this.amendinfo = false
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    selectAddress(e) {
        const data = this.usualaddress.find(item => item.addrFull == e)
        this.cascaderValue = [data.provinceCode, data.cityCode, data.areaCode]
        const list = data.addrFull.split(' ')
        let address = ''
        list.forEach((item,index) => {
            if (index > 2) {
                address = address + item
            }
        })
        this.form.addrDetail = address
    },
    // 选择发货地
    cascaderChange(e) {
        this.cascaderValue = e
        this.form.addrDetail = ''
    },
    getCheckOption(id) {
      if (!id) {
        this.$message.error('二级分类为空')
        return
      }
      // 查找分类属性
      querySecondAttr(id).then((res) => {
        let data = res.data
        data.forEach((item, index) => {
          if (item.attrValue) {
            item.attrValue = item.attrValue.split(',')
          }
          item.value = index === 0 ? item.attrValue[0] : undefined
        })
        this.selectClassify.property = data
        console.log('分类属性', this.selectClassify.property)
      })
      // 查找发票信息
      queryCateroryName(id).then((res) => {
        console.log('发票信息', res)
        this.invoiceInfo.list = res.data
        // 查找默认值
        if (!res.data.length) {
          this.invoiceInfo.index = undefined
        } else {
          const index = this.invoiceInfo.list.findIndex(
            (item) => item.isDefault
          )
          this.invoiceInfo.index = index != -1 ? index : 0
        }
      })
    },
    typeTaxChange(e) {
      const { id, policyName, vatRate } = this.taxRateList.find(
        (item) => item.id === e
      )
      this.taxRate = { id, policyName, vatRate }
    },
    siteChange(e) {
      console.log(e)
      // if (e.id === this.siteObj.id) {
      //   getdefault({ purchaserId: this.$route.query.entityId }).then(res => {
      //     this.siteObj = res.data
      //   })
      //   return
      // }
      this.siteObj = e
    },
    setSite() {
      this.siteType = 2
      // if (!this.form.deliveryAddressId) {
      //   this.$message.error('请先切换地址')
      //   return
      // }
      this.showSite = true
    },
    // 获取默认地址
    getdefaults() {
      getdefault({
        purchaserId: this.$route.query.entityId
      }).then((res) => {
        if (res.code === 200) {
          this.siteObj = res.data
        }
      })
    },
    //  发票管理
    getsupplierinf() {
      getsupplierinf({
        entityCharacter: 'P',
        id: this.$route.query.entityId
      }).then((res) => {
        if (res.code === 200 && res.data) {
          this.billInfo = {
            id: this.$route.query.entityId,
            supplierName: '',
            unifiedCode: '',
            invoiceBankName: '',
            invoiceBankAcc: '',
            invoiceMobile: '',
            invoiceEmail: '',
            invoiceAddr: '',
            ...res.data
          }
        }
      })
    },
    create () {
      // 转换二级分类属性参数
      const attributes = {}
      this.selectClassify.property.forEach(item => {
          attributes[item.attrName] = item.value
      })
      const obj = {
        attributes: JSON.stringify(attributes),
        // deliveryAddressId: this.siteObj.id,
        categorySecondTaxCodeId: this.invoiceInfo.list[this.invoiceInfo.index].id, // 税区类别id
        taxRegionPolicyId: this.policyId, // 税区id
        specName: this.selectClassify.property.length && this.selectClassify.property[0].value || ' ', // 商品规格（可以勾选不显示规格，则用空格代替）
        taxableUnitPrice:this.data_[0].taxableUnitPrice,
        ...this.form
      }
      console.log(obj);
      this.loading = true
      create(obj).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.$message.success(res.message)
          this.$router.push(`/succee-order?type=stock&purchaserId=${this.$route.query.entityId}&id=${res.data}`)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.add-product-container {
  margin: 0 auto;
  max-width: 1440px;
  min-width: 990px;
  font-size: 16px;
  .container-title {
    margin: auto;
    // width: 1100px;
    @media screen and (max-width: 1440px) {
      width: 100%;
      max-width: 1440px;
      min-width: 990px;
      padding: 0 15px;
    }
  }
  .container {
    font-size: 14px;
    margin-bottom: 32px;
    // display: flex;
    // justify-content: center;
    margin: 0 auto;
    @media screen and (max-width: 1440px) {
      width: 100%;
      max-width: 1440px;
      min-width: 990px;
      padding: 0 15px;
    }
    @media screen and (min-width: 1440px) {
      // width: 1100px;
    }
    .add-content {
      border:0;
      .cascader-alert {
        margin-bottom: 32px;
      }
      /deep/ .ant-card-head {
        background: #e9e9e9;
      }
      /deep/ .ant-col {
        margin-bottom: 12px;
      }
      .label-right {
        line-height: 32px;
        text-align: right;
      }
      /deep/ .ant-alert-with-description.ant-alert-no-icon {
        padding: 6px 16px;
      }
      /deep/ .ant-card {
        margin-bottom: 32px;
      }
      .l-card {
        position: relative;
        .title {
          content: '分类属性';
          position: absolute;
          top: -12px;
          left: 16px;
          background: #fff;
          z-index: 1;
          font-weight: bold;
          padding: 0 12px;
          color: #000;
        }
      }
    }
  }
  .container-border {
    border: 1px solid #e4e4e4;
    padding: 24px;
    position: relative;
    display: flex;
    align-items: center;
    .label {
      float: left;
    }
    .label-text {
      color: #999999;
    }
    .col-text {
      margin-left: 60px;
    }
    .site {
      color: #1abc9c;
    }
    .edit {
      width: 150px;
    }
    /deep/ .ant-col {
      margin-bottom: 0 !important;
    }
  }
  .matter {
    font-weight: 700;
    color: #ff0000;
  }
  .fs14 {
    font-size: 14px;
  }
  .fs18 {
    font-size: 18px;
  }
  .fs16 {
    font-size: 16px;
    vertical-align: text-bottom;
  }
  .fs24 {
    font-size: 24px;
  }
  .title-left {
    font-size: 16px;
    color: #666666;
    margin-top: 20px;
  }
  .title-right{
    color: #7F7F7F;
  }
  .rule {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
  .col {
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .create {
    text-align: right;
    margin-bottom: 64px;
    button {
      width: 180px;
    }
  }
}
.font-error {
  color: @error;
}

.w140 {
  width: 150px;
  height: 40px;
  color: #999;
  background: rgb(245, 245, 245);
  border: none;
  &:hover {
    color: rgb(51, 51, 51);
  }
}


.p-table-title {
  display: flex;
  & > div {
    border-bottom: 2px solid rgba(128, 128, 255, 1);
    // margin-right: 5px;
    text-align: center;
    padding: 15px 0;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      background-color: #fff;
      right: 0px;
      bottom: -2px;
    }
    &:last-child:after {
      height: 0;
    }
  }
  .p-name {
    width: 20%;
  }
  .bill-name {
    width: 17%;
  }
  .p-type {
    width: 13%;
  }
  .p-price {
    width: 15%;
  }
  .p-number {
    width: 8%;
  }
  .p-tax {
    width: 10%;
  }
  .p-sub {
    flex: 1;
    margin-right: 0;
  }
}
.s-total {
    margin-block: 12px;
    /deep/ .ant-col {
      width: 14%;
      text-align: center;
    }
  }
  /deep/ .ant-table-footer {
  background: rgba(192, 255, 255, 1);
  padding: 10px 0;
}
.table-footer {
  display: flex;

  .remark {
    width: 50%;
    display: flex;
    padding: 0 10px;
    .remark-label {
      width: 50px;
    }
  }
  .f-total {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // .total-text{
    //   margin-top: 10px;
    // }
  }
  .total-pic {
    padding-bottom: -20px;
    width: 14%;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: red;
    font-weight: bold;
  }
}

.p_table {
  .p_sub_text {
    color: red;
    font-weight: bold;
  }
  /deep/ .ant-table-thead > tr > th {
    background: #fff;
    border-bottom: 1px solid rgba(159, 255, 255, 1);
  }
  /deep/ .ant-table-tbody > tr > td {
    // background: #fff;
    background-color: rgba(217, 255, 255, 1);
    border-bottom: 1px solid rgba(159, 255, 255, 1);
  }
  /deep/.ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background-color: rgba(217, 255, 255, 1);
  }
  .square {
    background-color: #fff;
  }
}

.invoice-title {
  font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
  color: #666666;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 6px;
  text-align: center;
  line-height: 36px;
  display: inline-block;
  border-bottom: 5px double #797979;
}
.invoiceTable {
  margin-top: 16px;
  margin-bottom: 42px;
  /deep/ .ant-table-small {
    border: none;
  }
  /deep/ .ant-table-small > .ant-table-content > .ant-table-body {
    margin: auto;
  }
  /deep/.ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: transparent;
  }
  /deep/ .ant-table-header-column {
    display: inline !important;
  }
  /deep/ .ant-table-tbody {
    background: #fff;
  }
  /deep/ .ant-table-bordered .ant-table-body > table {
    border: 1px solid #a30014;
    border-right: 0;
    border-bottom: 0;
    color: #a30014;
  }
  /deep/ .ant-table-thead > tr > th {
    color: #a30014;
    background: #fff;
    border-right: 1px solid #a30014 !important;
    border-bottom: 1px solid #a30014;
  }
  /deep/ .ant-table-thead > tr {
    &:nth-child(2) > th {
      border-bottom: none;
    }
  }
  /deep/ .ant-table-tbody > tr > td {
    border-bottom: none;
    border-right: 1px solid #a30014 !important;
  }
  /deep/ .ant-table-row-level-0 {
    &:last-child > td {
      border-bottom: 1px solid #a30014;
    }
    &:nth-last-child(2) > td {
      border-bottom: 1px solid #a30014;
    }
    &:nth-last-child(3) > td {
      border-bottom: 1px solid #a30014;
    }
  }
  .table-row {
    padding: 0 4px;
    text-align: left;
    color: #666;
    span {
      margin-right: 8px;
      color: #a30014;
    }
    .table-col {
      position: relative;
      button {
        position: absolute;
        bottom: -50px;
        left: 0;
        z-index: 99;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(228, 228, 228, 1);
      }
    }
    /deep/ .ant-col {
      line-height: 24px;
    }
  }
  /deep/ .table-list {
    color: #666666;
  }
  /deep/ .font-foot {
    color: #a30014;
    text-align: left !important;
  }
}
.pt5 {
    padding-top: 5px;
}
.pt10{
    padding-top: 10px;
}

.create-order {
    font-weight: 700;
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
    font-size: 16px;
    padding: 10px 30px;
    padding-bottom: 54px;
    color: #000000;
    background: #fff;
    margin-bottom: 100px;
    position: relative;
    .btn-w {
      width: 180px;
    }
  }
</style>